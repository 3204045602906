@keyframes color-transition {
  0% {
    background-color: #006854;
  }
  25% {
    background-color: #d2007d;
  }
  50% {
    background-color: #0a4e87;
  }
  75% {
    background-color: #000000;
  }
  100% {
    background-color: #006854;
  }
}

.body-color-transition {
  animation: color-transition 10s infinite;
  min-height: 100vh;
}

.logo {
  transition: transform 0.3s ease; /* Add this line */
}

.logo:hover {
  transform: scale(1.15); /* Add this line */
}
